<template>
    <div class="container-fluid view applications">
        <component :is="currentComponent"></component>
    </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "Applications",
    computed: {
        currentComponent() {
            const userType = this.$store.getters.user_type;
            if (userType == 'Client' || userType == 'Management') {
                return 'UserApplicationsPanel';
            }
            return 'CandidateApplicationsPanel';
        }
    },
    components: {
        CandidateApplicationsPanel: defineAsyncComponent(() =>
            import("@/views/applications/CandidateApplicationsPanel")
        ),
        UserApplicationsPanel: defineAsyncComponent(() =>
            import("@/views/applications/UserApplicationsPanel")
        ),
    }
}
</script>